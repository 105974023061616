#introduction-board{
    min-height: calc(100vh - 80px);
    background: rgb(255,255,255);
    /* background: linear-gradient(90deg, rgb(255, 255, 255) 0%, rgb(255, 255, 255) 24%, rgb(211, 228, 232) 100%); */
}
.ib-left-side{
    padding-left: 60px;
}
.ib-left-side h1{
    font-weight: 900;
    font-size: 3.5rem;
    color: rgb(0, 0, 0);
}

.service-type-intro {
    background: linear-gradient(90deg,  rgb(44, 44, 44) 24%, rgb(163, 224, 235) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.ib-btn-container{
    width: 100% !important;
    display: flex;
}
.ibBtn{
    width: 200px;
    border-radius: 20px;
    transition: transform 0.2s ease-in-out;
}
.ibBtn:hover{
    transform: translateY(-10%)
}
.btnQuote{
    font-weight: 800;
}


.ib-right-side {
    border-radius: 20px;
    overflow: hidden;
    max-height: 700px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .background-image {
    width: 90%;
    height: 90%;
    background-image: url("../img/services3.png");
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 20px;
    background-position: center;
    animation: moveInCircles 100s infinite linear;
  }
  
  /* Definir keyframes para la animación circular */
  @keyframes moveInCircles {
    0% {
      transform:  rotate(0deg);
    }
    25% {
      transform:  rotate(90deg);
    }
    50% {
      transform:  rotate(180deg);
    }
    75% {
      transform:  rotate(270deg);
    }
    100% {
      transform:  rotate(360deg);
    }
  }
  

  @media (max-width:1200px){
    .background-image {
      height: 68%;
    }
    .ib-left-side h1{
      font-weight: 900;
      font-size: 2.6rem;
      color: rgb(0, 0, 0);
  }
}

  @media (max-width:992px){
    .ib-right-side {
      max-height: 700px;
    }
    .background-image {
      width: 90%;
      height: 58%;
    }
    .ib-left-side h1{
      font-weight: 900;
      font-size: 2.3rem;
      color: rgb(0, 0, 0);
  }
}
@media (max-width:768px){
  .ib-right-side {
    height: 500px;
  }
  .background-image {
    width: 80%;
    height: 80%;
  }
  .ib-left-side {
    padding: 0 30px 30px 30px;
    text-align: center;
  }
  .ib-btn-container{
    width: 100% !important;
    display: flex;
    align-items: center !important;
    margin: 0 auto;
    justify-content: center;
    
  }
  .ib-left-side h1{
    margin-top: 50px !important;
  }
}

@media (max-width:576px){
    
    .btnQuote{
        margin-bottom: 20px;
    }
    
    .header-second-title{
        font-size: 2rem;
        max-width: 320px;
        margin: 0 auto;
    }
    .ib-right-side {
       display: none !important;
       height: 0 !important;
    }
    .ib-left-side h1{
      margin-top: 35px !important;
    }
}


