.page-footer{
	color: black;
	font-family: "sohne-var","Helvetica Neue","Arial",sans-serif;
}

#newsletterInput{
	border-radius: 10px;
	padding: 10px;
}
footer a {
    color: black !important;
}