
.hero-aboutUs{
    min-height: 320px;
}
.aboutUs-title{
    font-size: 45px;
    font-weight: 850;
    margin-bottom: 20px;
}
.hero-left-side{
    background-color:  #F6F9FC;
    border-radius: 20px;
}
.quote-container{
    max-height: 300px;
}
.quote-container img{
    height: 100%;
    width: 100%;
    overflow: hidden;
    border-radius: 20px;
}
blockquote{
    font-size: 18px;
    font-weight: bold;
    background-color:  #F6F9FC;
    padding: 35px;
    border-radius: 20px;

}
.subtitle-aboutUs{
    font-size: 35px;
    font-weight: 800;
    text-align: center;
}

#history p{
    max-width: 80%;
    margin: 0 auto;
}
/* VALUES */
.values-cards-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 15px;
    border-radius: 10px;
    background-color:  #F6F9FC;
}

.value-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-bottom: 40px;
}

.value-card header {
    height: 100px;
    width: 100px;
    background-color: rgb(187, 218, 219);
    padding: 15px;
    border-radius: 50%;
    transform: translateY(40%);
}

.value-card header img {
    height: 100%;
    width: 100%;
    border-radius: 50%;
    object-fit: cover;
}

.value-card main {
    background-color: white;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    min-height: 250px;
    border-radius: 20px;
    padding: 20px;
    padding-top: 50px;
}

@media (max-width: 768px) {
    .values-cards-container {
        grid-template-columns: 1fr;
    }

    .value-card main {
        width: 100%;
    }
    .hero-left-side{
        padding: 40px 0px;
        margin-top: 50px;
    }
    blockquote{
        margin-top: 48px;
        text-align: center;
    }
    #history p{
        max-width: 94%;
        margin: 0 auto;
    }
    .quote-container{
        max-height: 400px;
    }
}

@media (max-width: 576px) {
    .value-card main {
        padding: 15px;
    }
}
