#timeline{
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
    /* background: linear-gradient(90deg, rgb(255, 255, 255) 0%, rgb(255, 255, 255) 24%, rgb(211, 228, 232) 100%); */

    /* background: linear-gradient(180deg, rgb(255, 255, 255) 0%, rgb(255, 255, 255) 24%, rgb(244, 253, 255) 100%); */

}
#timeline h2{
    font-weight: 900;
    font-size: 45px;
    margin-bottom: 50px;
    text-align: center;
}

  .category {
    text-align: center;
    font-size: 1.2rem;
    font-weight: 500;
    color: #6c757d;
  }
  
  h2 {
    text-align: center;
    font-size: 2.5rem;
    margin-bottom: 2rem;
    color: #333;
  }
  
  .col-8 {
    max-width: 80%;
  }
  
  .StepLabel {
    display: flex;
    align-items: center;
    font-size: 1.2rem;
  }
  
  .StepLabel .MuiStepLabel-labelContainer {
    margin-left: 10px;
  }
  
  .StepContent {
    padding-left: 40px;
    padding-bottom: 20px;
  }
  
  .StepContent .MuiTypography-h6 {
    font-size: 1.4rem;
    margin-bottom: 1rem;
  }
  
  .StepContent .MuiTypography-body1 {
    font-size: 1rem;
    margin-bottom: 1rem;
  }
  
  .MuiButton-contained {
    background-color: #007bff;
    color: #fff;
    border-radius: 20px;
  }
  
  .MuiButton-contained:hover {
    background-color: #0056b3;
  }
  
  .MuiButton-outlined {
    border-color: #007bff;
    color: #007bff;
    border-radius: 20px;
  }
  
  .MuiButton-outlined:hover {
    background-color: rgba(0, 123, 255, 0.1);
  }
  