.navbar{
    border-bottom: 1px solid white;
    background-color:  #F6F9FC;

}
.navbar-toggler {
    margin-right: 25px;
}
nav {
    font-size: 18px;
    margin: 0 auto;
}
.nav-link {
    color: black !important;
    cursor: pointer;
    width: 120px;
    position: relative;
    text-align: center;
    font-weight: 550;
}
.nav-item {
    position: relative;
    transition: transform 0.3s ease; 
}
.navbarBtnModal{
    margin-left: 15px;
}
.nav-item:hover{
    transform: translateY(-13%)
}
.navbar-brand {
    height: 90px;
    width: 95px;
    margin-left: 30px;
}
.navbar-brand img {
    height: 100%;
    width: 100%;
}

.navbarBtnModal{
    width: 60%;
    margin-left: 0;
    display: flex;
    justify-content: center;
}

@media (max-width: 992px) {
    .nav-item {
        text-align: center;
    }
    .navbar-toggler {
        margin-right: 10px;
    }
   
    .navbar-nav{
        align-items: center;
    }
    .navbar-collapse{
        padding-right: 0px !important;
    }
    .nav-item::after {
        transition: width 0.1s ease;
    }
    .navbarBtnModal{
        width: 50%;
        display: flex;
    }
}

