
@keyframes show {
  from  {
    opacity: 0;
    scale: 25%;
  }
  to {
    opacity: 1;
    scale: 100%;
  }
}
 .timeline-board,.accordion,.faq-body-left,.why-us-card,.carousel-container,.sc-image-side,.sameTwoCards{
  view-timeline-name : --container;
  view-timeline-axis: block;
  animation-timeline: --container;
  animation-name: show;

  animation-range: entry 25% cover 50%;
  animation-fill-mode: both;
}
*{
  font-family:"sohne-var","Helvetica Neue","Arial",sans-serif;
}

