#faq-board{
    min-height: 100vh;
    width: 100%;
    background-color: #F6F9FC;; 
}
.section-name{
    padding: 20px 0px;
}
.section-name h4{
    color: rgb(111, 124, 110);
}
.section-name h1{
    color: black;
    font-weight: 900;
}
.faq-body h2,.faq-footer h2{
    font-weight: 750;
}
.faq-footer{
    background-color:rgb(230, 230, 230);
    padding: 40px 0;
    border-radius: 10px;
}
.faq-btn{
    border-radius: 10px;
}

.faq-body-left{
    display: flex;
    flex-direction: column;
    padding: 0px 40px;
    align-items:center;
    justify-content: center;
}
.faq-body-left h4{
    font-weight: 800;
    font-size: 30px;
}
.contact-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
}

.contact-form .form-group {
    position: relative;
    margin-bottom: 30px;
}

.contact-form input,
.contact-form textarea {
    border: none;
    background-color: transparent;
    width: 100%;
    padding: 10px 0;
    font-size: 16px;
}

.contact-form label {
    position: absolute;
    left: 10px;
    top: 10px;
    pointer-events: none;
    transition: all 0.3s ease;
    font-weight: bold;
    font-size: 16px;
}

.contact-form input:focus,
.contact-form input:not(:placeholder-shown),
.contact-form textarea:focus,
.contact-form textarea:not(:placeholder-shown) {
    outline: none;
}

.contact-form input:focus + label,
.contact-form input:not(:placeholder-shown) + label,
.contact-form textarea:focus + label,
.contact-form textarea:not(:placeholder-shown) + label {
    top: -20px;
    font-size: 15px;
    color: #000;
}


/* -----acordion css ----- */
.accordion-button:focus {
    outline: none !important; /* Elimina el outline */
    box-shadow: none !important; /* Opcional: elimina el shadow si también quieres quitarlo */
}
.accordion-item, .accordion-button{
    background-color: transparent !important;
}
.accordion-button{
    border-radius: 10px;
    color: black !important;
    font-weight: 700;
    
}  
.accordion-button::after {
    background-image: url("https://cdn-icons-png.flaticon.com/512/17/17132.png");
}
.accordion-button:not(.collapsed)::after{
    background-image:url("https://cdn-icons-png.flaticon.com/512/43/43625.png") ;
}
#accordionExample{
    border-radius: 10px;
}
.accordion-item{
    margin-bottom: 10px;
    border: none;
}
div.accordion-item .show{
    background-color:white;
    border-radius: 10px;
    transition: background-color 0.3s ease; /* Agrega una transición suave para el cambio de color de fondo */

}
.faq-img-container{
    height: 400px;
    position: absolute;
    right: -200px;
}
.faq-img-container img{
    height: 100%;
    transform: rotate(55deg);
}

