#reviews{
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* background-color: #F8F9FA;  */
    /* background: linear-gradient(90deg, rgb(255, 255, 255) 0%, rgb(255, 255, 255) 24%, rgb(211, 228, 232) 100%); */
}
#reviews h4{
    font-weight: 900;
    font-size: 45px;
    margin-bottom: 25px;
}
#reviews .title-detail{
    font-size: 20px;
    font-weight: 900;
    margin-bottom: 40px;
}
.title-description{
    width: 60%;
    margin-bottom: 50px;
}
.carousel-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}
.review-description {
    margin-bottom: 40px;
    font-size: 16px;
    color: black;
    padding: 20px;
    border-radius: 10px;
    font-style: oblique;
    /* background: white */
    background-color: #F6F9FC;
}
.fa-quote-left{
    font-size: 70px;
    color:black;
    border-right: 2px solid  rgba(209, 206, 206, 0.793);
    padding-right: 20px;
}
.thumbnails {
    display: flex;
    justify-content: center;
    gap: 20px;
}

.thumbnail {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    transition: transform 0.2s;
}

.thumbnail img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 10px;
    border: 2px solid transparent;
}

.thumbnail.selected img {
    padding: 5px;
    border-style:dashed;
    border-color: #F6F9FC;
}

.thumbnail p {
    margin: 0;
    font-size: 14px;
    color: #555;
}

.thumbnail:hover {
    transform: scale(1.2);
}

@media (max-width:576px){
    .title-description{
        width: 80%;
        margin-bottom: 50px;
    }
    .review-description {
        font-size: 16px;
    }
    .review-description-inner{
        flex-direction: column;
    }
    .fa-quote-left{
        font-size: 40px;
        margin-bottom: 10px;
        border-right: 0;
    }
    .thumbnail img {
        width: 80px;
        height: 80px;
    }
}

@media (max-width:576px){
    .ib-btn-container{
        width: auto;
        flex-direction: column;
    }
    .review-description {
        font-size: 15px;
    }
}