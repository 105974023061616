#why-us-container{
    /* background: linear-gradient(90deg, rgb(255, 255, 255) 0%, rgb(255, 255, 255) 24%, rgb(211, 228, 232) 100%); */
    background-color: #F6F9FC;

}
#why-us-container h2{
    font-weight: 900;
    font-size: 45px;
}
.why-us-card{
    width: 500px;
    background-color: white;
    padding: 30px;
    border-radius: 20px;
    margin-bottom: 20px;
}
.card-number{
    font-weight: 800;
    font-size: 45px;
    /* background: linear-gradient(90deg, rgb(123, 185, 171), gray); */
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.card-title{
    font-weight: 900;
    font-size: 30px;
    margin-bottom: 20px;
}

.services-stylish-img-container-down{
    position: absolute;
    height: 400px;
    width: 400px;
    left: -150px;
    transform: rotate(45deg);
}

@media (max-width:992px){
    .services-stylish-img-container-down{
        left: -250px;
    }
}
@media (max-width:768px){
    .services-stylish-img-container-down{
        left: -260px;
    }
    
}
@media (max-width:576px){
    .services-stylish-img-container-down{
        left: -350px;
        bottom: -150px;
    }
}
