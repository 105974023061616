
.intro-sustainability {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px; /* Añadir un poco de margen entre las filas */
}

.is-left-side {
    display: flex;
    align-items: center !important;
    flex-direction: column;
    min-height: 400px;
    justify-content: center !important;
    text-align: center;
}

.is-left-side h3 {
    font-size: 45px;
    font-weight: 800;
    color: green;
}

.wc-left-side {
    height: 100%; /* Cambiado para permitir que la imagen ocupe todo el contenedor */
    display: flex;
    justify-content: center;
    align-items: center;
}

.wc-left-side img {
    width: 100%; /* Asegurar que la imagen ocupe todo el ancho del contenedor */
    height: auto; /* Mantener la relación de aspecto */
    object-fit: cover;
    border-radius: 20px;
}

.sustainability-list {
    list-style: none;
}

.sustainability-list li {
    list-style: none;
    background-color: #F6F9FC;;
    margin-bottom: 20px;
    border-radius: 20px;
    padding: 20px;
}

.sustainability-subheader {
    font-size: 45px;
    font-weight: 800;
    color: green;
}

@media (max-width: 768px) {
    .sustainability-list {
        padding: 0px;
    }
    
}

@media (max-width: 576px) {
    .wc-left-side img {
        margin-top: 30px;
        width: 100%; /* Ajustado para ocupar el 100% del contenedor en pantallas pequeñas */
    }
    .is-left-side h3 {
        margin-top: 20px;
    }
    .order-first {
        order: -1;
    }
}
