#services {
    min-height: 100vh;
    display: flex;
    align-items: center;
    flex-direction: column;
}
.category{
    font-weight: 700;
    color: rgb(141, 187, 182) !important;
    margin: 30px 0 !important;
}
#services h2{
    font-weight: 900;
    font-size: 45px;
    margin-bottom: 50px;
    text-align: center
}
.services-description{
    max-width: 60%;
    margin-bottom: 50px;
    text-align: center;
}
.services-card-article{
  background-color:  #F6F9FC;
  border-radius:20px;
  margin-bottom: 30px;
  
}
.services-card-article header i {
  padding: 15px;
  border-radius: 10px;
}
.services-card-article ul{
  text-decoration: none;
  list-style: none;
  padding-left: 0;
}
.fa-square-check{
  color: rgb(0, 115, 255);
}
.sc-image-side{
  height: 500px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sc-image-side img{
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 20px;
}

.service-article header i{
  background-color: green;
  padding: 10px;
  border-radius: 10px
}
.same-cards-row {
  display: grid !important;
  grid-template-columns: repeat(2, 1fr) !important;
  width: 95%;
}

@media (max-width: 992px) {
  .services-card-article {
    flex-direction: column-reverse;
  }
  .sc-image-side{
    margin-bottom: 30px;
    height: 350px;
  }
  .same-cards-row {
    grid-template-columns: repeat(1, 1fr) !important;
    width: 100%;

  }
}

@media (max-width: 576px) {
  #services h2{ 
    font-size: 35px;
    /* max-width: 65%; */
  }
  .services-description{
    max-width: 85%;
  }
  .services-card-article main h3{
    font-size: 1.1rem;
  }
  .sc-image-side {
    height: 230px !important;
    display: flex;
    align-items: center;
    justify-content: center !important;
    overflow: hidden;
    padding: 0;
  }

}


